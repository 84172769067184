export default function Footer() {
  const t = useI18n()
  const year = dayjs().year()

  const { fromApp } = useCurrent()
  if (fromApp) return null

  return (
    <div className={ cn('py-20 bg-gray-100/80 text-gray-600 text-sm') }>
      <div className='container'>
        <div className='flex flex-wrap md:space-x-6 mb-5'>
          <div className='flex-1 flex pb-6 md:pb-0'>
            <C.Image
              src={ cdn('svg/logo_icon_g2.svg') }
              className='w-7 h-7 mr-3 shrink-0'
              noLoading
            />
            <div>{ t('common:footer.description') }</div>
          </div>
          <div className='w-full px-1 pb-3 md:py-0 md:w-1/4 flex flex-row flex-wrap'>
            <LinkBtn text={ t('common:footer.contactUs') }
              onClick={ () => h.contactUs({ title: t('panel:contactUs.title') }) }
              src={ cdn('svg/email.svg') }
            />
            <LinkBtn text='iOS'
              link='https://itunes.apple.com/tw/app/hairdodo-hao-fa-xing-gei-ni/id1094314351?l=zh&mt=8'
              src={ cdn('svg/ios.svg') }
            />
            <LinkBtn text={ t('common:footer.instagram') }
              link='https://www.instagram.com/stylemap_official'
              src={ cdn('svg/instagram_g4.svg') }
            />
            <LinkBtn text='Andriod'
              link='https://play.google.com/store/apps/details?id=tw.hairbook.photo'
              src={ cdn('svg/android.svg') }
            />
          </div>
          <div className='p-3 w-full md:w-1/4'>
            <C.Link href={ h.path.joinUs() } noUnderline>
              <C.Button variant='outlined' className='w-full border-gray-400'>{t('common:stylistJoin')}</C.Button>
            </C.Link>
          </div>
        </div>

        <Hr />
        <div className='text-center'>
          <div>{ t('common:footer.rightReserved', { year }) }</div>
          <div className='pt-1'>
            <C.Link href={ h.path.terms() }>{ t('common:footer.terms') }</C.Link>
            <span className='px-1'>•</span>
            <C.Link href={ h.path.sitemaps({ type: 'tag', page: 0 }) }>{ t('common:footer.sitemaps') }</C.Link>
          </div>
        </div>
      </div>
    </div>
  )
}

const LinkBtn = ({ text, src, link, ...props }) => {
  const renderComponent = () => {
    if (link) return <C.Link href={ link }>{ text }</C.Link>
    return (
      <div className='cursor-pointer underline'
        { ...props }
      >
        { text}
      </div>
    )
  }
  return (
    <div className='w-1/2 pb-1 flex items-center text-[0.8rem]'>
      <C.Image
        src={ src }
        className='w-4 h-4 shrink-0 mr-2'
        noLoading
      />
      { renderComponent() }
    </div>
  )
}

const Hr = () => (
  <div className='py-4'>
    <div className='border-t' />
  </div>
)
